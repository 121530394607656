import React, { useEffect, useState } from "react"
import {
  BodyContainer,
  ModalHeader,
  Title,
  ButtonContainer,
  Link,
  ModalWrapper,
} from './TrovaPopUp.style'; 
import { Modal } from "@vfit/shared/atoms";
import { getBodyModalHeight } from "./modal.utils";


const TrovaModal = (props) => {

  const [height, setHeight] = useState(500);
  const { 
   data: {
    title = " ",
    instructionsInformation = [] },onCloseModal,show } = props

    useEffect(() => {
      if(show)
      setHeight(getBodyModalHeight("body-container"))
    },[show])
  
  return (

    <Modal 
    show={show}
    height={height}
    onCloseModal={onCloseModal}>
    <ModalWrapper>
    <BodyContainer id={"body-container"}>
      <ModalHeader dangerouslySetInnerHTML={{ __html: title }} />
      {instructionsInformation?.map((item, index) => {
        const { title, button: {isBlank ="true",label= "",link=""}} = item;
        return (
          <React.Fragment key={index}>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
            <ButtonContainer>
            <a target={isBlank === "true" ? "_blank" : undefined} href={link} className="modal-link">
              {label}
            </a>
            </ButtonContainer>

          </React.Fragment>
        );
      })}
    </BodyContainer>
  </ModalWrapper>

  </Modal>

  )
}

export default TrovaModal;